<template>

  <div :class="{flixFocus: focused}" :key="active.length" @mouseover="focused = true" @mouseleave="focused = false">
    <getLabel :data="data" :focus="focused" :key="focused" />
    <ul class="select">
    <li v-for="(term, index) in data.values" :class="{active: active[index]}" :key="index+key" @click="getClick(index)">
      {{ term.label }}
    </li>
    </ul>
    <div v-if="data.description" class="small" :class="{flixFocus: focused}">{{ data.description }}</div>
  </div>

</template>

<script>

import getLabel from '@/components/formBuilder/parts/label'

export default {

  props: { data: Object, callback: Function },
  components: { getLabel },

  data () {
    return {
      active: {},
      focused: false,
      key: new Date().getTime()
    }
  },

  methods: {
    getActive (index) {
      return this.active[index]
    },

    getClick (index) {
      this.active[index] = !this.active[index]
      this.key = new Date().getTime()

      var r = []

      this.data.values.forEach(function (v, k) {
        if (this.active[k]) { r.push(v.value) }
      }.bind(this))

      this.callback(r)
    }

  },

  mounted () {
    Object.keys(this.data.values).forEach(function (k) {
      this.active[k] = false
    }.bind(this))
  }
}

</script>
<style lang="sass" scoped>
  @import '@/components/formBuilder/style/variables.sass'
  @import '@/components/formBuilder/style/form/label.sass'
  @import '@/components/formBuilder/style/form/select.sass'
</style>
